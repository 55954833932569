import Modal from 'react-bootstrap/Modal';
import close from "../../images/close.svg";
import { useDispatch, useSelector } from 'react-redux';
import {toast} from 'react-toastify'
import {updateUser} from '../../redux/ActionCreators'

export const RewardAddressModal = ({show, closeModal}) => {
  const authedUser = useSelector(s => s.authedUser)
  const dispatch = useDispatch()

  const setRewardAddress = () => {
    const rewardAddress = document.getElementById('reward-address').value

    if (rewardAddress){
      fetch(`${process.env.REACT_APP_BASE_URL}/users/reward/address`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({rewardAddress})
      }).then(r => r.ok ? r.json() : {success: false})
      .then(r => {
        if (r.success){
          dispatch(updateUser({...authedUser.authedUser, rewardAddress}));
          toast('Thank you submitting your address. You will receive 20 USDT in 24 hours.', {
            theme: 'dark',
            position: 'bottom-right'
          })
        }
        else{
          toast(r.msg ? r.msg : 'Failed to set address', {
            theme: 'dark',
            position: 'bottom-right'
          })    
        }
      })
    }
    else{
      toast('Address Required', {
        theme: 'dark',
        position: 'bottom-right'
      })
    }
    document.getElementById('reward-address').value = ''
    closeModal()
  }
  
  return(
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Body>
        <div className="connectModal kycModal1 pt-0">
          <img className='closeIco' src={close} alt="" onClick={closeModal} />
          <h2>Enter TRC Address</h2>
          <div className="exchangeMain" style={{flexDirection: 'column'}}>
            <p className="mt-3">Enter your USDT(TRC) Network Address on which you would like to receive your reward of 20 USDT. It may take upto 24 hours to receive the reward.</p>
            <div>
              <label>TRC Address:</label><br/>
              <input className='w-100 input' id="reward-address" placeholder='TWvLeT97VH1wfjG9mGr1ousGhhuEE4JTBR' />
            </div>
            <button className='themeBtn' onClick={setRewardAddress}>Submit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}