import React, { useEffect, useState } from 'react';

export default function ReferralUsers(){
  const [users, setUsers] = useState([])

  const getUsers = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/referral/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json())
    .then(res => {
      if (res.success){
        setUsers(res.users.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
      }
    })
  }

  useEffect(() => {
    getUsers()
  }, [])

  return(
    <>
      <div className='mt-3'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div style={{marginLeft: '10px'}}>
              <button onClick={() => getUsers(localStorage.getItem('token'))}>Refresh Users</button>
            </div>
          </div>

          <div>
            <span>Total: {users.length} users </span>
          </div>
        </div>
        <table style={{width: '100%'}}>
          <thead>
            <th>Full Name</th>
            <th>Email</th>
            <th>Points</th>
          </thead>
          <tbody>
            {users.map(u => {
              return(
                <tr style={{border: '1px solid grey'}} key={`admin-${u._id}`}>
                  <td style={{ padding: '10px' }}>{u.username}</td>
                  <td style={{ padding: '10px' }}>{u.email}</td>
                  <td style={{ padding: '10px' }}>{u.points}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

