import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AdminPages } from './component/admin/admin';
import { Route, Link, useHistory, NavLink, useLocation, useParams, Switch, Redirect  } from "react-router-dom";
import { loginUser, setBalances, setConnectedExchange, setOrders, setTotalUSDBalance } from "./redux/ActionCreators";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import { ConditionModal } from "./component/conditionModal";
import axios from "axios";


import logo from "./images/logo.svg"
import loginArt from "./images/loginArt.png"
import closeIco from "./images/close.svg"
import inputBorder from "./images/inputBorder.svg"
import showPassImg from "./images/showPass.svg"
import hidePass from "./images/hidePass.svg"
import { LandingPage } from "./component/landingPage/landingPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Privacy } from "./component/landingPage/privacy";
import { Terms } from "./component/landingPage/terms";
import { Loader } from './component/loader/loader';

import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { QnAModal } from "./component/QnAModal";
import { disableIllegalCharacters, disablePaste } from "./helpers";
import { ReferralLanding } from "./component/referral/referralLanding";

const getOrders = async (uid, token) => {
  return fetch(process.env.REACT_APP_BASE_URL+"/users/get-orders-user/", {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }).then(r => r.json())
}

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal]= useState(false);
  const [inputData, setInputData]= useState({});
  const [showPass, setShowPass] = useState(false);

  const login = async () => {
    let email = document.getElementById('login-email').value;
    let password = document.getElementById('login-password').value;
    if (email && password){
      email = email.trim()
      setInputData({email:email,password:password})
      fetch(process.env.REACT_APP_BASE_URL+"/users/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password
        })
      }).then(r => {
        if (r.ok){
          return r.json();
        }
        else{
          return {success: false, msg: 'Failed to Login'};
        }
      }).then(res => {
        if (res.success){
          if(res.user.enable2FA || !res.user.emailVerified){
            if(!res.user.emailVerified){
              setShowModal(true)
              sendOTP(email,'register')
            }else{
              setShowModal(true)
              sendOTP(email)
            }
          }else{
            res.user.token = res.token;
            dispatch(loginUser(res.user));
            dispatch(setConnectedExchange(res.connectedExchange));
            if (res.user.approved){
              fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
                method: 'GET',
                headers : {
                  Authorization: `Bearer ${res.user.token}`
                }
              }).then(r => r.json()).then(res => {
                dispatch(setBalances(res.balances));
                dispatch(setTotalUSDBalance(res.total));
              })
              getOrders(res.user._id, res.user.token)
              .then(r => {
                if (r.success){
                  dispatch(setOrders(r.orders));
                }
              })
            }
            history.push('/dashboard');

          }
        }
        else{
          if(res?.err?.name == "IncorrectPasswordError"){
            toast(res?.err?.message ? res?.err?.message : 'Failed', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }else{
            toast(res.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }
        }
      });
    }
    else{
      console.log('Please fill all the fields')
      toast('Please fill all the fields', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const sendOTP = async (email,signupOtp) => {
    let payload ={};
    if(signupOtp){
      payload = {
        email: email,
        signupOtp:signupOtp
      };
    }else{
      payload = {
        email: email,
      };
    }

    const url = `${process.env.REACT_APP_BASE_URL}/users/genrateOTP`;
    let response = await axios.post(url, payload);
  } 
  const verifyOTP = async (otp) => {
    const payload = {
      email: inputData.email,
      otp: otp,
    };

    const url = `${process.env.REACT_APP_BASE_URL}/users/verifyOTP`;
    let response = await axios.post(url, payload);
    if(response?.data?.success){
      response.data.user.token = response.data.token;
      dispatch(loginUser(response.data.user));
      dispatch(setConnectedExchange(response.data.connectedExchange));
      if (response.data.user.approved){
        fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
          method: 'GET',
          headers : {
            Authorization: `Bearer ${response.data.user.token}`
          }
        }).then(r => r.json()).then(res => {
          dispatch(setBalances(res.balances));
          dispatch(setTotalUSDBalance(res.total));
        })
        getOrders(response.data.user._id, response.data.user.token)
        .then(r => {
          if (r.success){
            dispatch(setOrders(r.orders));
          }
        })
      }
      history.push('/dashboard');

      // let {email,password} = inputData;
      // if (email && password){
      //   fetch(process.env.REACT_APP_BASE_URL+"/users/login", {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       email,
      //       password
      //     })
      //   }).then(r => {
      //     if (r.ok){
      //       return r.json();
      //     }
      //     else{
      //       return {success: false, msg: 'Failed to Login'};
      //     }
      //   }).then(res => {
      //     if (res.success){
      //         res.user.token = res.token;
      //         dispatch(loginUser(res.user));
      //         dispatch(setConnectedExchange(res.connectedExchange));
      //         if (res.user.approved){
      //           fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
      //             method: 'GET',
      //             headers : {
      //               Authorization: `Bearer ${res.user.token}`
      //             }
      //           }).then(r => r.json()).then(res => {
      //             dispatch(setBalances(res.balances));
      //             dispatch(setTotalUSDBalance(res.total));
      //           })
      //           getOrders(res.user._id, res.user.token)
      //           .then(r => {
      //             if (r.success){
      //               dispatch(setOrders(r.orders));
      //             }
      //           })
      //         }
      //         history.push('/dashboard');
      //     }
      //     else{
      //       toast(res.msg, {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //         theme: 'dark'
      //       });
      //     }
      //   });
      // }
    }else{
      toast("Invalid OTP", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  } 
   
  function handleCredentialResponse(response) {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/google-signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: response.credential })
    }).then(r => r.json())
    .then(res => {
      if (res.success){
        res.user.token = res.token;
        dispatch(loginUser(res.user));
        dispatch(setConnectedExchange(res.connectedExchange));
        if (res.user.approved){
          fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
            method: 'GET',
            headers : {
              Authorization: `Bearer ${res.user.token}`
            }
          }).then(r => r.json()).then(res => {
            dispatch(setBalances(res.balances));
            dispatch(setTotalUSDBalance(res.total));
          })
          getOrders(res.user._id, res.user.token)
          .then(r => {
            if (r.success){
              dispatch(setOrders(r.orders));
            }
          })
        }
        history.push('/dashboard');
      }
      else{
        toast(res.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    });
  }
  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: "778232948621-up9osdc9uiaa6n8sobuj709jqhc58jsd.apps.googleusercontent.com",
      callback: handleCredentialResponse
    });
    window.google?.accounts.id.renderButton(
      document.getElementById("gButtonDivSignin"),
      { type: 'standard', theme: "filled_black", size: "medium", 
      shape:'pill',
      text: 'Continue with Google',
      'width': 125,
      'height': 70,
        }  // customization attributes
    );  
  }, [window.google]);

  useEffect(() => {

  }, [])

  return(
    <>
    <div className="loginPage">
      <div className="w-100">
        <div className="row mx-0 ai-center">
          <div className="col-md-6 px-0 col1">
              <div className="logoDiv" onClick={()=>history.push('/')}>
                <img src={logo} alt="" />
              </div>
              <div className="artDiv">
                <img src={loginArt} alt="" />
              </div>
          </div>
          <div className="col-md-6 px-0 col2">
            <div className="loginForm">
              <div className="innerForm">
                {/* <div className="formBorder">
                  <img src={signInBorder} alt="" />
                </div> */}
                <h1 >Sign In</h1>
                <div className="mb-5">
                  <div className="hrLine"></div>
                </div>
                <div className="form-group">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input 
                  {...disablePaste}
                  {...disableIllegalCharacters}
                  className="form-control" type="text" id="login-email" placeholder="Email" />
                </div>
                <div className="form-group showPassInput mb-2">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" type={showPass?"text":"password"} id="login-password" placeholder="Password" />
                  {showPass?
                    <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                    }
                </div>
                <div className="forgetPass">
                  <NavLink to="/forget-password">Forgot password?</NavLink>
                </div>
                {/* <button className="themeBtn" onClick={login}>Sign In</button> */}
                <div className="signBtn" style={{display: 'flex', flexDirection: 'column'}}>
                  {/* <img className="img-fluid" src={signinBtn} alt=""  /> */}
                  <button className="themeBtnHome mx-auto mb-3" style={{width: 'fit-content'}} onClick={login}>Sign in</button>
                  <div id="gButtonDivSignin" data-text="signup_with" className="mx-auto"></div>
                </div>
                <div className="notReg mb-0 mt-4">
                  Not registered yet? <NavLink to="/register">Sign Up</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OtpModal show={showModal} setShow={setShowModal} verifyOTP={verifyOTP} />
    </>
  );
}

const ForgetPassword = () => {
  const history = useHistory();
  const forgetPass = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/users/forget`;
    return axios.post(url, body);
  }

  const forgotPassword = async () => {
    try {
        if (!document.getElementById('reset-email').value){
          return toast('Please enter your email', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
        }
        const payload = {
          email: document.getElementById('reset-email').value
        };
        const response = await forgetPass(payload);
        
        if (response?.data?.message == "Please verify your mail first") {
            return toast(response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
            });
        
        }
        if (response?.data?.message == "incorrect email address") {
        return toast(response?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
        });
        }
        if (response?.data?.error) {
            return toast("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
            });
        }
        return toast("Email sent", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });

    } catch (e) {
        toast("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
        });
    }
  };

  return(
    <>
    <div className="loginPage">
      <div className="w-100">
        <div className="row mx-0 ai-center">
          <div className="col-md-6 px-0 col1">
              <div className="logoDiv" onClick={()=>history.push('/')}>
                <img src={logo} alt="" />
              </div>
              <div className="artDiv">
                <img src={loginArt} alt="" />
              </div>
          </div>
          <div className="col-md-6 px-0 col2">
            <div className="loginForm">
              <div className="innerForm">
                {/* <div className="formBorder">
                  <img src={signInBorder} alt="" />
                </div> */}
                <h1 >Forgot Password</h1>
                <div className="mb-5">
                  <div className="hrLine"></div>
                </div>
                <div className="form-group">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input 
                  {...disablePaste}
                  {...disableIllegalCharacters}
                  className="form-control" type="text" id="reset-email" placeholder="Email" />
                </div>
                {/* <button className="themeBtn" onClick={login}>Sign In</button> */}
                <div className="signBtn">
                  {/* <img className="img-fluid" src={signinBtn} alt=""  /> */}
                  <button className="themeBtnHome" onClick={forgotPassword}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {id} = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const [show, setShow] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const resetPass = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/users/reset-password`;
    return axios.post(url, body);

  }

  const onSubmit = async () => {
    try {
      const validatorResponse = await validation();
      
      if (validatorResponse) {
        return toast(validatorResponse, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
      
      setLoader(true);
      const payload = {
        ...data,
        token:id
      };
      // const response = await resetPass(payload);
      let response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/reset-password`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${id}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({password: data.password})
      }).then(r => r.ok ? r.json() : ({success: false}));

      setLoader(false);
      if (!response.success){
        console.log(response);
        toast('Failed to reset password. Try again with a new link.', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return;
      }
      // if (response?.error) {
      //   console.log(response?.error);
      // }
      // if (response?.data?.message == "Link expired") {
      //   return alert(response?.data?.message);
      // }

      handleShow()

      setTimeout(() => {
        handleClose()
        history.push("/");
      }, 3000);
    } catch (e) {
      setLoader(false);
      toast("Error while Reset Password", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  };

  const validation = async () => {
    // var letter = /[a-zA-Z]/; 
    // var number = /[0-9]/;
    // var valid = number.test(data.password) && letter.test(data.password);

    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password != data.confirmPassword) {
      return "Confirm Password is must be same as Password";
    }
    if(data.confirmPassword.includes(' ')){
       return "Spaces are not allowed in password";
    }
    // if(!valid || data.password.length < 6) {
    //   setPassError(true)
    //   return  "password not valid"
    // }
    // else(setPassError(false))
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };


  return(
    <>
    <div className="loginPage">
      <div className="w-100">
        <div className="row mx-0 ai-center">
          <div className="col-md-6 col1 px-0">
              <div className="logoDiv" onClick={()=>history.push('/')}>
                <img src={logo} alt="" />
              </div>
              <div className="artDiv">
                <img src={loginArt} alt="" />
              </div>
          </div>
          <div className="col-md-6 col2 px-0">
            <div className="loginForm">
              <div className="w-100">
                {/* <div className="formBorder">
                  <img src={signInBorder} alt="" />
                </div> */}
                <h1>Reset Password</h1>
                <div className="mb-5">
                  <div className="hrLine"></div>
                </div>
                <div className="form-group showPassInput">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" name="password" onChange={onInputChange} type={showPass?"text":"password"} placeholder="Password" />
                  {showPass?
                    <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                    }
                </div>
                <div className="form-group showPassInput mb-2">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" type={showPassConfirm?"text":"password"}  name="confirmPassword" onChange={onInputChange} placeholder="Confirm Password" />
                  {showPassConfirm?
                    <img onClick={()=>setShowPassConfirm(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPassConfirm(true)} src={hidePass} alt="" />
                    }
                </div>
                <div className="signBtn">
                  {/* <img className="img-fluid" src={signinBtn} alt=""  /> */}
                  <button className="themeBtnHome" onClick={onSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
    <Modal.Body>
      {/* <img src={modalBorder} alt="" className="modalBorder" /> */}
      <div className="otpModalMain">
       {loader ?
       <h2 className="title">Loading..</h2>
       :
        <h2 className="title">Your new password has been saved successfully</h2>
       }
      
      </div>
    </Modal.Body>
  </Modal>
    </>
  );
}


const SetDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {id} = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)

  const [show, setShow] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setDetails = (body) => {
    const url = `${process.env.REACT_APP_BASE_URL}/users/set-details`;
    return axios.post(url, body);

  }

  const onSubmit = async () => {
    try {
      const validatorResponse = await validation();
      
      if (validatorResponse) {
        return toast(validatorResponse, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
      
      setLoader(true);
      const payload = {
        ...data,
        token:id
      };
      const response = await setDetails(payload);

      setLoader(false);
      if (response?.error) {
        console.log(response?.error);
      }
      if (!response?.data?.success){
        return toast(response?.data?.msg, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
      if (response?.data?.message == "Link expired") {
        return toast(response?.data?.message, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }

      handleShow()
      response.data.user = response?.data?.token;
      dispatch(loginUser(response?.data?.user))
      dispatch(setConnectedExchange(response?.data?.connectedExchange));

      setTimeout(() => {
        handleClose()
        history.push("/dashboard");
      }, 3000);
    } catch (e) {
      setLoader(false);
      toast("Error while Reset Password", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  };

  const validation = async () => {
    // var letter = /[a-zA-Z]/; 
    // var number = /[0-9]/;
    // var valid = number.test(data.password) && letter.test(data.password);

    if (!data.username) {
      return "Username is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password != data.confirmPassword) {
      return "Confirm Password is must be same as Password";
    }
    if(data.confirmPassword.includes(' ')){
       return "Spaces are not allowed in password";
    }
    // if(!valid || data.password.length < 6) {
    //   setPassError(true)
    //   return  "password not valid"
    // }
    // else(setPassError(false))
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };


  return(
    <>
    <div className="loginPage">
      <div className="w-100">
        <div className="row mx-0 ai-center">
          <div className="col-md-6 col1 px-0">
              <div className="logoDiv" onClick={()=>history.push('/')}>
                <img src={logo} alt="" />
              </div>
              <div className="artDiv">
                <img src={loginArt} alt="" />
              </div>
          </div>
          <div className="col-md-6 col2 px-0">
            <div className="loginForm">
              <div className="w-100">
                {/* <div className="formBorder">
                  <img src={signInBorder} alt="" />
                </div> */}
                <h1>Set Details</h1>
                <div className="mb-5">
                  <div className="hrLine"></div>
                </div>
                <div className="form-group">
                  <img className="inputBorder" src={inputBorder} alt="" />
                  <input className="form-control" name="username" onChange={onInputChange} type="text" placeholder="Username" />
                </div>
                <div className="form-group">
                  <img className="inputBorder" src={inputBorder} alt="" />
                </div>
                <div className="form-group mb-2">
                  <img className="inputBorder" src={inputBorder} alt="" />
                </div>
                <div className="form-group showPassInput">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" name="password" onChange={onInputChange} type={showPass?"text":"password"} placeholder="Password" />
                  {showPass?
                    <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                    }
                </div>
                <div className="form-group showPassInput mb-2">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" name="confirmPassword" onChange={onInputChange} type={showPassConfirm?"text":"password"} placeholder="Confirm Password" />
                  {showPassConfirm?
                    <img onClick={()=>setShowPassConfirm(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPassConfirm(true)} src={hidePass} alt="" />
                    }
                </div>

                <div className="signBtn">
                  {/* <img className="img-fluid" src={signinBtn} alt=""  /> */}
                  <button className="themeBtnHome" onClick={onSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
    <Modal.Body>
      {/* <img src={modalBorder} alt="" className="modalBorder" /> */}
      <div className="otpModalMain">
       {loader ?
       <h2 className="title">Loading..</h2>
       :
        <h2 className="title">Your new password has been saved successfully</h2>
       }
      
      </div>
    </Modal.Body>
  </Modal>
    </>
  );
}
const get_value = (id) => document.getElementById(id).value;
const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPass, setShowPass] = useState(false);
  const [showConditionModal,setShowConditionModal] = useState(false)
  const [showModal, setShowModal]= useState(false);
  const [whatsapp, setWhatsapp] = useState("");
  const [qnaModal, setQnAModal] = useState(false);

  const [showPassC, setShowPassC] = useState(false)

  const handleClose = () => setShowConditionModal(false);
  const handleShow = () => setShowConditionModal(true);

  function validatePassword(p) {
    let errors = [];
    if (p.length < 8) {
        errors.push("Your password must be at least 8 characters"); 
    }
    if (p.length > 24) {
      errors.push("Your password must be at most 24 characters"); 
    }
    if (p.search(/[a-z]/) < 0) {
      errors.push("Your password must contain at least one lowercase letter.");
    }
    if (p.search(/[A-Z]/) < 0) {
      errors.push("Your password must contain at least one uppercase letter.");
    }
    if (p.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one digit."); 
    }
    if (errors.length > 0) {
        toast(errors.join("\n"), {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        return false;
    }
    return true;
  }

  const register = async () => {
    let name = document.getElementById('register-name').value;
    let email = document.getElementById('register-email').value;
    let password = document.getElementById('register-password').value;
    let confirmPwd = document.getElementById('register-confirmpwd').value;
    let termsNCondition = document.getElementById('terms-condition').checked;
    let m = document.getElementById('register-user-type').value;
    const query = new URLSearchParams(window.location.search);

    if (!isValidPhoneNumber(whatsapp)){
      toast('Enter valid whatsapp number', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
      return;
    }
    if (name && email && password && confirmPwd && termsNCondition && m){
      if (m === "master"){
        setQnAModal(true);
        return toast('Please fill out the questionnaire', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
      else if (password === confirmPwd){
        if (password.length <= 24){
          // let regex = /^(?=.*[az])(?=.*[AZ])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,24}$/;
          if (validatePassword(password)){
            const validEmails = ['gmail.com', 'outlook.com', 'yahoo.com', 'aol.com']
            let emailstripped = email.split('@');
            console.log(emailstripped);
            if (emailstripped.length === 2){
              let emailOp = emailstripped[1];
              console.log(emailOp);
              if (validEmails.includes(emailOp)){
                fetch(process.env.REACT_APP_BASE_URL+"/users/register", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    name,
                    email,
                    password,
                    whatsapp,
                    invite: query.get('invite'),
                    master: m === "master"
                  })
                }).then(r => {
                  if (r.ok){
                    return r.json();
                  }
                  else{
                    return {success: false, msg: 'Failed to register'};
                  }
                }).then(res => {
                  if (res.success){
                    setShowModal(true)
                    sendOTP(email)
                    // res.user.token = res.token;
                    // dispatch(loginUser(res.user));
                    // history.push('/dashboard');
                  }
                  else{
                    toast(res.msg, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      theme: 'dark'
                    });
                  }
                });  
              }
              else{
                toast('Invalid Email.', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  theme: 'dark'
                });  
              }
            }
            else{
              toast('Invalid Email.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
              });
            }
          }
          else{
            // toast('Password should be: Atleast One Uppercase, One Lowercase, One Digit, One special character and should be 8-24 characters.', {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   theme: 'dark'
            // });  
          }
        }
        else{
          toast('Password should be less than 24 characters.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });  
        }
      }
      else{
        toast('Confirm password should be same', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    }
    else{
      toast('Please fill all the fields', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const sendOTP = async (email) => {
    const payload = {
      email: email,
      signupOtp:'register',
    };

    const url = `${process.env.REACT_APP_BASE_URL}/users/genrateOTP`;
    let response = await axios.post(url, payload);
  } 
  const verifyOTP = async (otp) => {
    try {
      let email = document.getElementById('register-email').value;
      let password = document.getElementById('register-password').value;
      const payload = {
        email: email,
        otp: otp,
      };
      const url = `${process.env.REACT_APP_BASE_URL}/users/verifyOTP`;
      let response = await axios.post(url, payload);
      if(response?.data?.success){
        response.data.user.token = response?.data.token;
        dispatch(loginUser(response?.data.user));
        dispatch(setConnectedExchange(response?.data.connectedExchange));
        if (response?.data.user.approved){
          fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
            method: 'GET',
            headers : {
              Authorization: `Bearer ${response?.data.token}`
            }
          }).then(r => r.json()).then(res => {
            dispatch(setBalances(res.balances));
            dispatch(setTotalUSDBalance(res.total));
          })
          getOrders(response?.data.user._id, response?.data.user.token)
          .then(r => {
            if (r.success){
              dispatch(setOrders(r.orders));
            }
          })
        }
        history.push('/dashboard');

        // if (email && password){
        //   fetch(process.env.REACT_APP_BASE_URL+"/users/login", {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //       email,
        //       password
        //     })
        //   }).then(r => {
        //     if (r.ok){
        //       return r.json();
        //     }
        //     else{
        //       return {success: false, msg: 'Failed to Login'};
        //     }
        //   }).then(res => {
        //     if (res.success){
        //         res.user.token = res.token;
        //         dispatch(loginUser(res.user));
        //         dispatch(setConnectedExchange(res.connectedExchange));
        //         if (res.user.approved){
        //           fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
        //             method: 'GET',
        //             headers : {
        //               Authorization: `Bearer ${res.user.token}`
        //             }
        //           }).then(r => r.json()).then(res => {
        //             dispatch(setBalances(res.balances));
        //             dispatch(setTotalUSDBalance(res.total));
        //           })
        //           getOrders(res.user._id, res.user.token)
        //           .then(r => {
        //             if (r.success){
        //               dispatch(setOrders(r.orders));
        //             }
        //           })
        //         }
        //         history.push('/dashboard');
        //     }
        //     else{
        //       toast(res.msg, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         theme: 'dark'
        //       });
        //     }
        //   });
        // }
      }else{
        toast("Invalid OTP", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        })
      }
      
    } catch (error) {
      console.log(error);
      toast("Invalid OTP", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      })
    }
  } 

  const submitQnA = () => {
    let qna = {
      qna1_name: get_value('qna-1-name'), qna1_email: get_value('qna-1-email'),
      qna1_exp: get_value('qna-1-exp'), qna1_platform: get_value('qna-1-platform'),
      qna1_currencies: get_value('qna-1-currencies'),

      qna2_strategy: get_value('qna-2-strategy'), qna2_risk: get_value('qna-2-risk'),
      qna2_leverage: get_value('qna-2-leverage'), qna2_analysis: get_value('qna-2-analysis'),
      qna2_trade_example: get_value('qna-2-trade-example'), qna2_volatile: get_value('qna-2-volatile'),

      qna3_roi: get_value('qna-3-roi'), qna3_ratio: get_value('qna-3-ratio'),
      qna3_trades: get_value('qna-3-trades'), qna3_loss: get_value('qna-3-loss'),

      qna4_updated: get_value('qna-4-updated'), qna4_understanding: get_value('qna-4-understanding'),
      qna4_indicators: get_value('qna-4-indicators'), qna4_altcoins: get_value('qna-4-altcoins'),

      qna5_principles: get_value('qna-5-principles'), qna5_why: get_value('qna-5-why'),
      qna5_benefit: get_value('qna-5-benefit'),

      qna6_scenario1: get_value('qna-6-scenario1'), qna6_scenario2: get_value('qna-6-scenario2'),

      qna7_platforms: get_value('qna-7-platforms'), qna7_automate: get_value('qna-7-automate'),
      qna7_apis: get_value('qna-7-apis'),

      qna8_final: get_value('qna-8-final'),
    };
    for (let key in qna){
      if (!qna[key]){
        return toast(`All fields are required`, {theme: 'dark', position: 'bottom-right'});
      }
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/users/master-application`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(qna)
    }).then(res => res.json())
    .then(r => {
      console.log(r);
      if (r.success){
        setQnAModal(false);
        return toast(`Success. We will get back to you by email you provided.`, {theme: 'dark', position: 'bottom-right'});
      }
      else{
        return toast(r.msg ? r.msg : 'Failed. Try again', {theme: 'dark', position: 'bottom-right'});
      }
    })
  }

  const [pwdVal, setPwdVal] = useState('');
  const [pwdFocues, setPwdFocued] = useState(false);
  return(
    <>
     <div className="loginPage">
      <div className="w-100">
        <div className="row mx-0 ai-center">
          <div className="col-md-6 col1 px-0">
              <div className="logoDiv" onClick={()=>history.push('/')}>
                <img src={logo} alt="" />
              </div>
              <div className="artDiv">
                <img src={loginArt} alt="" />
              </div>
          </div>
          <div className="col-md-6 col2 px-0">
          <div className="loginForm">
              <div className="innerForm">
                <h1>Sign Up</h1>
                <div className="notReg">
                Already have an account? <Link to="/login">Login</Link>
                </div>
                <div className="mb-5">
                  <div className="hrLine"></div>
                </div>
                <div className="form-group">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input 
                  {...disablePaste}
                  {...disableIllegalCharacters}
                  className="form-control" type="text" id="register-name" placeholder="Full Name" />
                </div>
                <div className="form-group">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input
                  {...disablePaste}
                  {...disableIllegalCharacters}
                  className="form-control" type="text" id="register-email" placeholder="Enter your Email" />
                </div>
                <div className="form-group">
                  <PhoneInput
                    className="form-control"
                    international
                    id="register-whatsapp"
                    countryCallingCodeEditable={false}
                    value={whatsapp}
                    onChange={setWhatsapp}
                    placeholder="Enter Whatsapp"
                  />
                </div>
                <div style={{position: 'relative'}} className="form-group showPassInput">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input value={pwdVal} onChange={e => setPwdVal(e.target.value)}  onFocus={() => {setPwdFocued(true); document.getElementById('pwd-reqs').classList.add("animate-tmp")}} onBlur={() => {setPwdFocued(false); document.getElementById('pwd-reqs').classList.remove("animate-tmp")}} className="form-control" type={showPass?"text":"password"} id="register-password" placeholder="Password" />
                    {showPass?
                    <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                    }
                <div style={{position: 'absolute', zIndex: '10', width: '100%', marginTop: '10px'}} id="pwd-reqs">
                  {pwdFocues ? <div style={{
                    background: 'var(--themeBg)',
                    borderRadius: '25px',
                    padding: '20px',
                    marginBottom: '10px'
                  }}>
                    <p style={{color: (pwdVal.length >= 8 && pwdVal.length <= 24) ? 'green' : 'red', marginBottom: '0px'}}>8-24 characters</p>
                    <p style={{color: pwdVal.search(/[a-z]/) < 0 ? 'red' : 'green', marginBottom: '0px'}}>At least 1 Lowercase Character</p>
                    <p style={{color: pwdVal.search(/[A-Z]/) < 0 ? 'red' : 'green', marginBottom: '0px'}}>At least 1 Uppercase Character</p>
                    <p style={{color: pwdVal.search(/[0-9]/) < 0 ? 'red' : 'green', marginBottom: '0px'}}>At least 1 Digit</p>
                  </div> : (<></>)}
                </div>

                </div>
          
                <div className="form-group showPassInput">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <input className="form-control" type={showPassC?"text":"password"} id="register-confirmpwd" placeholder="Confirm Password" />
                  {showPassC?
                    <img onClick={()=>setShowPassC(false)} src={showPassImg} alt="" />
                    :
                    <img onClick={()=>setShowPassC(true)} src={hidePass} alt="" />
                    }
                </div>
                <div className="form-group">
                  {/* <img className="inputBorder" src={inputBorder} alt="" /> */}
                  <select id="register-user-type" className="input form-control">
                    <option value="" disabled selected>Type</option>
                    <option value="master">Master Trader</option>
                    <option value="follower">Follower</option>
                  </select>
                </div>
                <div className="terms ">
                    <input type="checkbox" name="terms" id="terms-condition"  />
                    <span className="ml-2" onClick={handleShow}>Term & conditions</span>
                </div>
                <div className="signBtn" style={{display: 'flex', flexDirection: 'column'}}>
                  <button className="themeBtnHome mx-auto mb-3" style={{width: 'fit-content'}} onClick={register}>Sign Up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OtpModal show={showModal} setShow={setShowModal} verifyOTP={verifyOTP} />
    <ConditionModal show={showConditionModal} agreeFunc={() => {
      document.getElementById('terms-condition').checked = true
      handleClose();
    }} handleClose={() => {
      document.getElementById('terms-condition').checked = false
      handleClose();
    }} />
    <QnAModal
    show={qnaModal}
    handleClose={() => setQnAModal(false)}
    submit={submitQnA}
    />
    </>
  );
}

// array of paths which can be seen logged out
const validPaths = [
  (p) => p === '/', 
  (p) => p === '/register', 
  (p) => p === '/privacy', 
  (p) => p === '/terms', 
  (p) => p === '/login', 
  (p) => p.startsWith('/set-details'), 
  (p) => p.startsWith('/forget-password'), 
  (p) => p.startsWith('/reset-password')
];

// array of paths which can be seen either logged in and logged out boht or just logged in
const validSecPaths = [
  (p) => p === '/', 
  (p) => p === '/referral-system', 
  (p) => p === '/dashboard', 
  (p) => p === '/privacy', 
  (p) => p === '/terms', 
  (p) => p === '/portfolio', 
  (p) => p === '/assets', 
  (p) => p === '/copytrade', 
  (p) => p === '/profile', 
  (p) => p.startsWith('/followtrader'), 
  (p) => p === '/user-history', 
  (p) => p === '/billing', 
  (p) => p === '/user-ongoing-orders', 
  (p) => p === '/settings',
  (p) => p === '/referral-points', 
  (p) => p === '/subscription'
]
export default function Main(){
  const authedUser = useSelector(state => state.authedUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(true);

  const validatePath = (auth) => {
    if (auth){
      console.log(auth, location.pathname)
      if (!validSecPaths.some(s => s(location.pathname))){
        history.push("/dashboard");
      }
    }
    else{
      if (!validPaths.some(s => s(location.pathname))){
        history.push("/")
      }
    }
  }

  const validateJWT = (jwt) => {
    try {   
      fetch(process.env.REACT_APP_BASE_URL+"/users/validate-jwt", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      }).then(r => {
        if (r.ok){
          return r.json();
        }
        else{
          return {success: false}
        }
      }).then(res => {
        if (res.success){
          validatePath(true)
          dispatch(loginUser({...res.user, token: jwt}));
          dispatch(setConnectedExchange(res.connectedExchange));
          if (res.user.approved){
            fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
              method: 'GET',
              headers : {
                Authorization: `Bearer ${jwt}`
              }
            }).then(r => r.json()).then(res => {
              dispatch(setBalances(res.balances));
              dispatch(setTotalUSDBalance(res.total));
            });
            getOrders(res.user._id, localStorage.getItem('token'))
            .then(r => {
              if (r.success){
                dispatch(setOrders(r.orders));
              }
            })
          }
        }
        else{
          validatePath(false)
          localStorage.removeItem('token');
          history.push("/login");
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token')){
      validateJWT(localStorage.getItem('token'));
      setTimeout(() => {
        setLoader(false)
      }, 5000);
    }
    else{
      setLoader(false);
      if (location.pathname !== '/' && validSecPaths.some(s => s(location.pathname))){
        history.push("/login");
      }
      else if (!validPaths.some(s => s(location.pathname))){
        history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')){
      fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
        method: 'GET',
        headers : {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(r => r.json()).then(res => {
        dispatch(setBalances(res.balances));
        dispatch(setTotalUSDBalance(res.total));
      })
    }
  }, [location]);

  return(
    <>
      {Object.keys(authedUser.authedUser).length > 0 ? (
        <>
        {(location.pathname.split('/')[1] == "terms" || location.pathname.split('/')[1] == "reset-password" || location.pathname.split('/')[1] == "set-details" || location.pathname.split('/')[1] == "privacy") ?
        <>
        <Route exact path="/reset-password/:id" component={ResetPassword} />
        <Route exact={true} path="/privacy" component={Privacy} />
        <Route exact={true} path="/terms" component={Terms} />
        </>
        :
        <AdminPages validateJWT={validateJWT} />
        }
        </>
      ) : (
        <>
          <Route exact path="/register" component={Register} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/set-details/:id" component={SetDetails} />
          {/* <Route path="/*" component={() => <Redirect to="/" />} /> */}
          <Route path='/referral-system' component={ReferralLanding} />

        </>
      )}
      {loader ? <Loader loader={true} /> : <></>}
      <ToastContainer progressStyle={{background: '#67E1AE'}} />
    </>
  );
}

export const OtpModal = ({show,setShow,verifyOTP}) => {
  const [otp, setotp] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeOtp = (otp) => {
    setotp(otp);
  };

  const handleOtp = async (e) => {

    if (otp.length < 6) {
      toast("Please fill OTP code", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    } else {
     verifyOTP(otp)
    }
  };

  return(
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Body>
      {/* <img src={modalBorder} alt="" className="modalBorder" /> */}
      <div className="otpModalMain">
        <div className="closeIcon" onClick={handleClose}>
          <img src={closeIco} alt="" />
        </div>
      
        <h2 className="title">Email Verification</h2>
        <div className="mb-5"><div className="hrLine"></div></div>
        <div className="desc">
        we have sent you a code to your email address. <br />
        Enter the 6 digit code, received by email
        </div>
        <div className="otpInputMain">
          <OtpInput
            value={otp}
            onChange={handleChangeOtp}
            numInputs={6}
            isInputNum={true}
            placeholder="----"
            // separator={<span>-</span>}
            className="mx-1"
          />
        </div>
        {/* <div className="resend">
          Did not receive a code? <span>Resend code</span>
        </div> */}
        <div className="submitBtn">
          {/* <img onClick={handleOtp} src={verifyCode} alt="" /> */}
          <button className="themeBtnHome" onClick={handleOtp}>Verify Code</button>

        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}


