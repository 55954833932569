import React,{useEffect,useState} from 'react';
import { XAxis, Tooltip,AreaChart,Area, YAxis, Label} from 'recharts';

import "./portfolio.css"

import profitIco from "../../images/profitIco.svg"
import lossIco from "../../images/lossIco.svg"
import completeKyc from '../../images/complete-kyc.svg';
import kycCompleted from '../../images/kyc-completed.svg';

import { useSelector } from 'react-redux';
import { showBalance } from '../../helpers';

const PortfolioComp = () => {
  const balances = useSelector(state => state.balances);
  const authedUser = useSelector(state => state.authedUser);
  const [totalProfit, setTotalP] = useState(0);
  const [totalLoss, setTotalL] = useState(0);
  const [totalB, setTotalB] = useState(0);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-total-profit-loss/${authedUser.authedUser._id}`)
    .then(r => r.json()).then(r => {
      if (r.success){
        setTotalP(r.totalP);
        setTotalL(r.totalL);
      }
    })
  }, []);
  useEffect(() => {
    let t = 0;
    balances.balances.forEach(b => {
      t += (parseFloat(b.free)+parseFloat(b.locked))*b.usdPrice
    });
    setTotalB(t);
  }, [balances]);

  return (
    <div className="profileBInnerM themeCardGlobal">
    <div className="header">
      <h2 className='mb-0'>My Portfolio </h2>
    </div>
    <div className="pb-3">
    <div className="hrLine"></div>
    </div>
    <div className="balanceMain">
    <div className="row ai-center">
      <div className="col-xl-5">
        <div className="yourBalance">
          <div className="col1">
            <div>
              <div className="title text-center" style={{fontSize: '15px'}}>Your Balance</div>
              <div className="balance">${showBalance(totalB.toFixed(2), authedUser.authedUser.balanceHide)}</div>
            </div>
          </div>
          <div className="col2">
            <div className="profit1">
              <div className="innerBorder">
                <div className="title">Todays Profits</div>
                <div className="balance">
                  <span>+{(totalProfit).toFixed(4)}$</span>
                  <img src={profitIco} alt="" />
                  <div className="profit">{(parseFloat(balances.total) > 0 && totalProfit > 0) ? ((parseFloat(totalProfit)/(parseFloat(balances.total) - parseFloat(totalProfit)))*100).toFixed(4) : '0.00'}%</div>
                </div>
              </div>
            </div>
            <div className="profit1">
              <div className="innerBorder">
                <div className="title">Overall Profit / Loss</div>
                  <div className="balance">
                    <span>-${(totalLoss).toFixed(4)}</span>
                    <img src={lossIco} alt="" />
                    <div className="loss">-{(parseFloat(balances.total) > 0 && totalLoss > 0) ? ((parseFloat(totalLoss)/(parseFloat(balances.total) + parseFloat(totalLoss)))*100).toFixed(4) : '0.00'}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-7">
        <PortfolioChart />
      </div>
    </div>
    </div>
    </div>
  );
}


function Portfolio(props) {
  const authedUser = useSelector(state => state.authedUser);

  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
            {/* Content Row */}
            <div className="row ">
            <div className="col-12 mb-4" >
                <div className="portfolioPage">
                  {authedUser.authedUser.master ? (
                    <PortfolioComp />
                  )  : (
                    (authedUser?.authedUser?.kycVerified && authedUser?.authedUser?.approved) ? (
                      (authedUser?.authedUser?.paid) ? (
                        <PortfolioComp />
                      ) : (
                        <div className="notApproveYet">
                          <p>Dear User, </p>
                          <p>Please pay the subscription fees 3000 TENUP in order to start using the services of this platform.</p>
                          <p>Best regards,</p>
                          <p>The Tenup Copy Trading Team.</p>
                        </div>                      
                      )
                    ) : (
                      authedUser.authedUser?.kycName ? (
                        <div className="notApproveYet row">
                          <div className="col1 col-xl-7">
                            <h1 className="mt-2">Thank you for submitting your KYC documents! </h1>
                            <p>Our team will review your submission within 24 hours. If all the details are accurate and meet the requirements, we will approve your KYC and notify you via email.</p>
                            <p>If you have any questions in the meantime, please feel free to contact us at <b style={{color: `var(--themeGreen)`}}>support@copytrade.tenup.io</b></p>
                            <p>Thank you for your patience and cooperation.</p>
                          </div>
                          <div className="col2 col-xl-5 px-lg-0">
                            <div className="imgDiv">
                              <img src={kycCompleted} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="notApproveYet row">
                          <div className="col1 col-xl-7">
                            <h1 className="mt-2">Dear User,</h1>
                            <p>Please complete your KYC verification to start using the TenUp Copy Trading platform. After submission our team will carefully review your documents. Upon approval, you will receive an email notification with further details.</p>
                            <p>Should you have any inquiries, do not hesitate to contact us at <b style={{color: `var(--themeGreen)`}}>support@copytrade.tenup.io</b></p>
                            <p>We look forward to assisting you!</p>
                            <p>Team TenUp</p>
                          </div>
                          <div className="col2 col-xl-5 px-lg-0">
                            <div className="imgDiv">
                              <img src={completeKyc} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
          </div>
      </div>
    </>
  )
}

const months = {
  1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
};

function RevenueChart({timeline}) {
  const [ data, setData ] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-profit-loss/${timeline}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json()).then(res => {
      if (res.success){
        let d = res.groupedOrders.map(groupedOrder => updateTime(groupedOrder));
        setData(d);
      }
    });
  }, [timeline]);
  
  const updateTime = (groupedOrder) => {
    if (timeline === 'day'){
      let d = new Date(groupedOrder.time);
      groupedOrder.time = d.getDate();
    }
    if (timeline === 'month'){
      let d = new Date(groupedOrder.time);
      groupedOrder.time = months[d.getMonth()+1];
    }
    if (timeline === 'year'){
      let d = new Date(groupedOrder.time);
      groupedOrder.time = d.getFullYear();
    }
    return groupedOrder;
  }
  const getLoss = (label) => data.find(d => d.time === label) ? data.find(d => d.time === label).totalL?.toFixed(3) : 0;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{color: 'var(--themeGreen)'}}>
          <p className="label mb-0">{`Total Profit: ${payload[0].value}$`}</p>
          <p className="label mb-0">{`Total Loss: ${getLoss(label)}$`}</p>
        </div>
      );
    }
  
    return null;
  };

  return(
    <AreaChart width={500} height={200}  data={data} className="portfolio-chart w-100 h-100"
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
      <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#867DF9" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#867DF9" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#67e1ae" stopOpacity={1}/>
          <stop offset="95%" stopColor="#111" stopOpacity={0.2}/>
          </linearGradient>
      </defs>
      <XAxis dataKey="time">
        <Label value={`Time (${timeline})`} offset={0} position="insideBottom" />
      </XAxis>
      <YAxis label={{ value: 'Profit ($)', angle: -90, position: 'insideLeft' }} />
      {/* <CartesianGrid  strokeDasharray="3 3" /> */}
      <Tooltip content={<CustomTooltip/>} />
      <Area type="monotone" dataKey="totalP" stroke="#67e1ae" strokeWidth={1} fillOpacity={1} fill="url(#colorPv)" />
    </AreaChart>
  )
}

function PortfolioChart() {
  const orders = useSelector(s => s.orders);
  const [timeline, setTimeline] = useState('day');
  return(
    <div className="">
      <div className="">
        <div className="">
          <div className=" themeCardGlobal">
            <div className="card br-10 h-100 border-transparent overflow-hidden ">
              <div className="card-header border-transparent pb-1 pt-2 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 chart-heading">Total Volume</h6>
                <div className="dropdown no-arrow">
                  <a style={{ cursor: 'pointer' }} onClick={() => setTimeline('day')} className={`chart-time pr-4 ${timeline === 'day' ? 'chart-time-active' : ''}`}>1D</a>
                  <a style={{ cursor: 'pointer' }} onClick={() => setTimeline('month')} className={`chart-time pr-4 ${timeline === 'month' ? 'chart-time-active' : ''}`}>1M</a>
                  <a style={{ cursor: 'pointer' }} onClick={() => setTimeline('year')} className={`chart-time pr-4 ${timeline === 'year' ? 'chart-time-active' : ''}`}>1Y</a>
                  {/* <a className="dropdown-toggle px-2" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={kebab} className="w-4" alt="dropdown icon" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div> */}
                </div>
              </div>
              <div className="lineChartPieCMain">
                <div className='price'>
                  <h3 className="">${(orders.orders.reduce((partialSum, order) => partialSum + (order?.usdPrice ? parseFloat(order?.usdPrice) : 0), 0)).toFixed(2)}</h3>
                  {/* <span>+18.54%</span> */}
                </div>
                <div className="selectChartBtn">
                  {/* <div className="btnRightCut themeCardGlobal mr-2"  >
                      <img src={lineChartIco} alt="" className="ico" />
                      <span>Line Chart</span>
                  </div> */}
                  {/* <div className="btnRightCut themeCardGlobal "  >
                      <img src={pieChartIco} alt="" className="ico" />
                      <span>Pie chart</span>
                  </div> */}
                </div>
              </div>
              <div className="card-body chartMain" style={{ marginLeft: '10px', marginBottom: '18px' }}>
                <div className="chart-area">
                    <RevenueChart timeline={timeline} />                    

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export {Portfolio};
