import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { AuthedUser } from './reducers/authedUser';
import { Balances } from './reducers/balances';
import { Orders } from './reducers/orders';
import { Loaders } from './reducers/loader';

export const ConfigureStore = () => {
    const store = configureStore({
      reducer: {
        authedUser: AuthedUser,
        balances: Balances,
        orders: Orders,
        loaders: Loaders
      },
      // middleware: [thunk, logger]  
      middleware: [thunk]  
    });
    return store;
}