import React,{useEffect} from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ReactDOM from 'react-dom';


import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';

import {ReactComponent as DashboardIco } from '../../../images/dashboardIco.svg'
import {ReactComponent as AnalyticsIco } from '../../../images/analytics.svg'
import {ReactComponent as PortfolioIco } from '../../../images/portfolioIco.svg'
import {ReactComponent as MyAssetIco } from '../../../images/myAssetIco.svg'
import {ReactComponent as TradingIco } from '../../../images/tradingIco.svg'
import {ReactComponent as LogoutIco } from '../../../images/logoutIco.svg'
import {ReactComponent as MyTraderIco } from '../../../images/myTraderIco.svg'
import {ReactComponent as OngoingIco } from '../../../images/ongoingIco.svg'
import {ReactComponent as ProfileIco } from '../../../images/profileIco.svg'
import {ReactComponent as CoptTradeIco } from '../../../images/coptTradeIco.svg'

import profile from "../../../images/profile.jpg";
import logo from "../../../images/logo.svg";
import leftbar from "../../../images/leftbar.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/ActionCreators";
import { useMediaQuery } from 'react-responsive'


function Sidebar({sidebarToggle,setSidebarToggle}) {

  const authedUser = useSelector(state => state.authedUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' })

  useEffect(()=>{
    // sidebarFunctionality()
  })

    const sidebarFunctionality=()=>{
      /* eslint-disable */
        "use strict";
        $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
            $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide");
        }),
        $(window).resize(function () {
          $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
          $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").collapse("hide"));
            }),
            $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
                var o;
                768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
            }),
            $(document).on("scroll", function () {
                100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
            });
           
    }

    const logout = () => {
      dispatch(logoutUser());
      history.push("/login");
    }

    useEffect(() => {
      if(isMobile){
        setSidebarToggle(true)
      }
    }, [])
    
      
    if (authedUser?.authedUser?.admin){
      return(<></>)
    }
    else{
      return(
        <>

             {/* Sidebar */}
          <ul 
          className={sidebarToggle ?"navbar-nav sidebar sidebar-dark accordion zi-1 toggled":"navbar-nav sidebar sidebar-dark accordion zi-1 "} 
           id="accordionSidebar">
            {/* Sidebar - Brand */}
            {/* <div className="leftbarImg">
              <img src={leftbar} alt="" />
            </div> */}
            <Link className="sidebar-brand  mt-3" to="/">
              <div className="sidebar-brand-text">
                {/* <img src={logo} className="w-100" alt="apollo" /> */}
                Menu
              </div>
            </Link>
           
            {/* sidebar links */}
            <li className="nav-item  mt-5">
              <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard">
                <span className="icon">
                  <DashboardIco />
                </span>
                <span className="sidebar-item">Dashboard</span></NavLink>
            </li>
               {/* Nav Item - Pages Collapse Menu */}
            {/* <li className="nav-item ">
              <a className="nav-link sidebar-Link fw-500 collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <img className="" src={load} alt="" />
                <span className="">Pump</span>
              </a>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink className="nav-link sidebar-Link fw-500" to="/pump"> <span className="downCircle"></span>Pump</NavLink>
                  <NavLink className="nav-link sidebar-Link fw-500" to="/pump/detail"> <span className="downCircle"></span>Detail</NavLink>
                </div>
              </div>
            </li> */}
            <li className="nav-item  ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/portfolio">
                <span className="icon">
                  <PortfolioIco />
                </span>
                <span className="sidebar-item">My Portfolio</span></NavLink>
            </li>
            <li className="nav-item  ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/assets">
                <span className="icon">
                  <MyAssetIco />
                </span>

                <span className="sidebar-item">My Assets</span></NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/copytrade">
                <span className="icon">
                  <CoptTradeIco />
                </span>

                <span className="sidebar-item">{authedUser?.authedUser?.master ? 'Copy Trading' : 'All Traders'}</span></NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link sidebar-Link fw-500 setting" to="/user-history">
                <span className="icon">
                  <MyTraderIco />
                </span>
                <span className="">My Trades</span></NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link sidebar-Link fw-500 setting" to="/user-ongoing-orders">
                <span className="icon">
                  <OngoingIco />
                </span>

                <span className="sidebar-item">Ongoing</span></NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link sidebar-Link fw-500 setting" to="/profile">
                <span className="icon">
                  <ProfileIco />
                </span>

                <span className="sidebar-item">Profile</span></NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link sidebar-Link fw-500 setting" to="/billing">
                <span className="icon">
                  <MyAssetIco />
                </span>

                <span className="sidebar-item">Billing</span></NavLink>
            </li>
            {authedUser.authedUser.referral === 'waqarzaka' ? <li className="nav-item">
              <NavLink className="nav-link sidebar-Link fw-500 setting" to="/referral-points">
              <span className="icon">
                <MyAssetIco />
              </span>

              <span className="sidebar-item">Referral Points</span>
              </NavLink>
            </li> : <></>}
            <li className="nav-item mt-auto">
              <a className="nav-link sidebar-Link fw-500" onClick={logout}>
                <span className="icon">
                  <LogoutIco />
                </span>
                <span className="sidebar-item">Logout</span>
              </a>
            </li>
         
           {/* <li className="nav-item mt-auto">
               <a className="nav-link sidebar-Link " href="#" >
                <img className="img-profile rounded-circle" src={authedUser?.authedUser?.profilepic ? `${process.env.REACT_APP_BASE_URL}/${authedUser.authedUser?.profilepic}` : profile} />
                <div className="profile-div align-self-center">
                  <span className="status ">Logout</span>
                </div>
              </a>
           </li> */}
          </ul>
          {/* End of Sidebar */}

        </>
    )
    }    
}
export default Sidebar