import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { RewardAddressModal } from './rewardAddressModal'

const pointsComplete = (points) => points === 600

export const ReferralPoints = () => {
  const authedUser = useSelector(s => s.authedUser)
  const [points, setPoints] = useState(100)
  const [orderExists, setOrderExists] = useState(false)
  const [paymentExists, setPaymentExists] = useState(false)
  const [apikeyExists, setApikeyExists] = useState(false)
  const [reward, setReward] = useState(null)
  const [rewardModal, setRewardModal] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/points/earned`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.ok ? r.json() : {success:false})
    .then(res => {
      if (res.success){
        const { points, orderExists, paymentExists, apikeyExists, reward } = res
        setPoints(points)
        setPaymentExists(paymentExists)
        setOrderExists(orderExists)
        setApikeyExists(apikeyExists)
        setReward(reward)
      }
    })
  }, [])

  const openModal = () => {
    if (pointsComplete(points) && !authedUser.authedUser.rewardAddress){
      setRewardModal(true)
    }
  }
  
  if (Object.keys(authedUser?.authedUser).length === 0){
    return(
      <Redirect to='/login'/>
    )
  }
  else if (authedUser.authedUser.referral === 'waqarzaka'){
    return (
      <div className="container-fluid">
      {/* Content Row */}
      <div className="row">
        <div className={"col-12"} >
          <div className="dashboardMain">
              {/* <div className="exchangeSupportPage orderHisPage"> */}
              <div className="exchangeSupportPage">
                <div className="head">
                  <h2>Referral Points</h2>
                </div>
                
                <div style={{
                  padding: '30px',
                  border: '1px solid #3C3C3C',
                  background: 'var(--cardBg)',
                  borderRadius: '15px',
                  marginBottom: '15px',
                }} className='col-12'>
                  <div className='row'>
                    <div className='col-4'>
                      <div style={{color: 'var(--themeGreen)', fontSize: '18px'}}>Points Rewarded</div>
                      <div>
                        <span>
                        {points}/600
                        </span>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div style={{color: 'var(--themeGreen)', fontSize: '18px'}}>Points Claimed</div>
                      <div>{reward ? '600' : 0}</div>
                    </div>
                    
                    <div className='col-4'>
                      {reward ? (
                        <button className='themeBtn'>Reward Claimed</button>
                      ) : (
                        <button className='themeBtn' onClick={openModal} style={{opacity: pointsComplete(points) && !authedUser.authedUser.rewardAddress ?  1 : 0.5  }}>Claim Reward</button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="">

                    <div className="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                          <th scope="col">
                              <div className="">
                                <div><span>Action</span></div>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="">
                                <div><span>Points</span></div>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="">
                                <div><span>Status</span></div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">Register via Invite Link</div>
                                </div>
                              </div>
                            </td>
                            
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">100 Points</div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase" style={{color: 'green'}}>Done</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">KYC Verification</div>
                                </div>
                              </div>
                            </td>
                            
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">50 Points</div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase" style={{color: authedUser.authedUser.kycVerified ? 'green' : 'red'}}>{authedUser.authedUser.kycVerified ? 'Done' : 'Pending'}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">Subscribe to Copytrade</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">200 Points</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase" style={{color: paymentExists ? 'green' : 'red'}}>{paymentExists ? 'Done' : 'Pending'}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">Connecting Exchange</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">100 Points</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase" style={{color: apikeyExists ? 'green' : 'red'}}>{apikeyExists ? 'Done' : 'Pending'}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">First Trade Executed</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase">150 Points</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="innerDiv">
                                  <div className="volume text-uppercase" style={{color: orderExists ? 'green' : 'red'}}>{orderExists ? 'Done' : 'Pending'}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <RewardAddressModal show={rewardModal} closeModal={() => setRewardModal(false)} />
      </div>
    )    
  }
  else{
    return (<Redirect to='/dashboard'/>)
  }
}