import React from 'react'
import Typist from 'react-typist'
import { Navbar } from '../landingPage/navbar';
import referalBanner from '../../images/referalBanner.png'
import progress from '../../images/progess.png'
import referalProgram from '../../images/referalProgram.png'
import easyIco from '../../images/easyIco.png'
import transparentIco from '../../images/transparentIco.png'
import safeIco from '../../images/safeIco.png'
import './refferal.css';
import { Footer } from '../landingPage/footer';

export const ReferralLanding = () => {
  return (
    <div className='referalLandingPage landingPage'>
            <Navbar />
          <section className='sec1'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-7">
                        <div className="content">
                            <Typist stdTypingDelay={300}>
                                <h1 id='typewriter'>Referral Points Program – Earn Rewards with TenUp Copy Trading!</h1>
                            </Typist>

                            <div className="desc"> 
                                Welcome to the <span>TenUp Copy Trading Referral Points Program,</span> where you can earn exciting rewards by completing simple tasks. Here's how you can participate, earn points, and claim your <span>20 USDT reward</span>.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="imgDiv">
                            <div className="effect"></div>
                            <img src={referalBanner} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='howItSec'>
            <div className="container">
                <h2
                >How It Works</h2>
                <div className="desc">Our referral program is designed to reward users for completing specific actions. Each task you complete earns you points, and once you accumulate 600 points, you become eligible to claim your reward.</div>
            </div>
        </section>
        <section className='beakdownSec'>
            <div className="container">
                <h2 className='homeHeading'>Tasks and Points Breakdown</h2>
                <div className="tagline">Complete the following tasks to earn points:</div>
                <div className="table-responsive">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Register via invite link</td>
                                <td>100 Points</td>
                            </tr>
                            <tr>
                                <td>Complete KYC Verification</td>
                                <td>50 Points</td>
                            </tr>
                            <tr>
                                <td>Subscribe to copyTrade</td>
                                <td>200 Points</td>
                            </tr>
                            <tr>
                                <td>Connect your exchange</td>
                                <td>100 Points</td>
                            </tr>
                            <tr>
                                <td>Execute your first trade</td>
                                <td>150 Points</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="total">Total Points to Earn: 600 Points</div>
            </div>
        </section>
        <section className='claimSec'>
            <div className="container">
                <h2 className="homeHeading text-center">How to Claim Your Reward</h2>
                <div className="tagline text-center">Once you accumulate all 600 points:</div>
                <div className="cCard">
                    <p><span>01</span> A Claim Button will become enabled in the Referral Points section of your dashboard.</p>
                    <p><span>02</span> Click on the Claim Button and submit your USDT (TRC-20) wallet address.</p>
                    <p><span>03</span> Our team will process your request, and you will receive your 20 USDT reward within 24 hours.</p>
                </div>
            </div>
        </section>

        <div className="sec4">
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-6">
                        <div className="imgDiv">
                            <div className="effect"></div>
                            <img src={progress} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <h1 className='homeHeading mb-3'>Track Your Progress</h1>
                            <div className="desc mb-4">You can view your progress in the Referral Points section of your dashboard. Each task's status will show as:</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span>     Done: Task completed, and points awarded.</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span>     Pending: Task is yet to be completed.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className='sec5' id="feature">
            <div className="container">
                <div className="secTitle">Why Start</div>
                <h1 className="homeHeading">Copy Trading?</h1>
                <div className="secDesc">Unlock the potential of cryptocurrency investment with Tenup Copy Trading software. Experience effortless earnings, underpinned by simplicity, transparency, and security. Elevate your investment journey to new heights, all while enjoying peace of mind.</div>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="cCard">
                            <div className="imgDiv">
                                <img src={easyIco} alt="" />
                            </div>
                            <div className="title">Invite Your Friends</div>
                            <p className="desc">Use your referral link to get others onboard and start earning points!</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="cCard">
                            <div className="imgDiv">
                                <img src={transparentIco} alt="" />
                            </div>
                            <div className="title">Earn Points Automatically</div>
                            <p className="desc">Once a task is completed, your points will be updated instantly in your dashboard.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="cCard">
                            <div className="imgDiv">
                                <img src={safeIco} alt="" />
                            </div>
                            <div className="title">Fast <br /> Rewards</div>
                            <p className="desc">After claiming your reward, our team ensures you receive your USDT within 24 hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="sec4">
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-6">
                        <div className="content">
                            <h1 className='homeHeading mb-3'>Why Join the Referral Program?</h1>
                            <div className="desc mb-4">You can view your progress in the Referral Points section of your dashboard. Each task's status will show as:</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span> Simple Tasks: Complete easy tasks to earn points.</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span> Guaranteed Rewards: Earn 20 USDT once all tasks are completed.</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span> Quick Payouts: Rewards are processed and sent quickly.</div>
                            <div className="desc mb-0"><span style={{fontSize:23}}>●</span> Transparent Tracking: Easily monitor your progress and claim your reward without hassle.</div>
                        </div>
                    </div> 
                    <div className="col-lg-6">
                        <div className="imgDiv">
                            <div className="effect"></div>
                            <img src={referalProgram} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <section className='startEarnSec'>
                    <h2 className='homeHeading text-center mb-4'>Start Earning Now!</h2>
                    <p>Log in to your <span>TenUp Copy Trading</span> account, head to the <span>Referral Points</span> section, and start completing tasks to earn your <span>600 points</span> today! The more you engage, the closer you are to claiming your <span>20 USDT reward.</span></p>
                    <p>For any questions or assistance, feel free to contact our support team. Start earning rewards with TenUp Copy Trading now!</p>
            </section>
        </div>
        <Footer />
        
    </div>
  )
}
